<template>
  <div class="entry-control">
    <label for="">{{ label }}</label>
    <input type="text" :placeholder="'Enter ' + label" @input="$emit('input', $event.target.value)" :value="value">
  </div>
</template>

<script>
export default {
  name: "EntryControl",
  props: {
    label: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped lang="scss">
.entry-control {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  label {
    display: block;
    font-size: 14px;
  }

  input {
    font-size: 16px;
    border: 1px solid transparent;
    padding: 15px;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    background-color: #292929;
    outline: none;
    color: #e1e3e6;
  }
}
</style>