<template>
  <div class="container car-list">
    <div class="header">
      <h1>List of cars</h1>
      <div class="header-controls">
        <div @click="selectedFiltering = 'search'" :class="selectedFiltering === 'search' ? 'active' : ''" style="mask: url(/icons/search.svg) no-repeat center / contain;"></div>
        <div @click="selectedFiltering = 'assistant'" :class="selectedFiltering === 'assistant' ? 'active' : ''" style="mask: url(/icons/microphone.svg) no-repeat center / contain;"></div>
      </div>
    </div>
    <div class="filters">
      <div class="search" v-if="selectedFiltering === 'search'">
        <dropdown-control label="Car brand" :options="brands" v-model="selectedBrand"></dropdown-control>
        <dropdown-control label="Car model" :options="models" v-model="selectedModel"></dropdown-control>
        <dropdown-control label="Number of doors" :options="doors" v-model="selectedDoors"></dropdown-control>
        <dropdown-control label="Car class" :options="carClass" v-model="selectedClass"></dropdown-control>
        <dropdown-control label="Car options" :options="options" v-model="selectedOption"></dropdown-control>
        <entry-control label="Price up to" v-model="selectedPrice"></entry-control>
        <button class="filter-clear" @click="filterClear"><i
            style="mask: url(/icons/cross.svg) no-repeat center / contain;"></i>Clear filter
        </button>
      </div>
      <assistant-control v-if="selectedFiltering === 'assistant'" @result="setFilterAI"></assistant-control>
    </div>
    <div class="content" v-if="cars.length > 0">
      <div class="card" v-for="(car, index) in cars" :key="index">
        <div class="card-header">
          <span>{{ car.name }} {{ car.model }}</span>
          <span>${{ car.price }}</span>
        </div>
        <div class="card-body">
          <img :src="car.image" alt="Car image">
          <div class="info">
            <div class="info-point">
              <span>Class: </span>
              <span>{{ car.class }}</span>
            </div>
            <div class="info-point">
              <span>Doors: </span>
              <span>{{ car.doors }}</span>
            </div>
            <div class="info-point" v-if="car.options.length">
              <span>Options: </span>
              <span>{{ car.options.toSpliced().splice(0, 1).join(', ') }} <span v-tooltip="car.options.join(', ')">+{{ car.options.length }} more</span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h3>No results were found based on the specified parameters</h3>
    </div>
  </div>
</template>

<script>
import {CARS} from "@/data/parsedCars";
import DropdownControl from "@/components/DropdownControl.vue";
import EntryControl from "@/components/EntryControl.vue";
import AssistantControl from "@/components/AssistantControl.vue";
import {isEmpty} from "@/services/utils";

export default {
  name: 'App',
  components: {
    AssistantControl,
    EntryControl,
    DropdownControl
  },
  data() {
    return {
      selectedFiltering: 'search',
      selectedBrand: null,
      selectedModel: null,
      selectedDoors: null,
      selectedClass: null,
      selectedOption: null,
      selectedPrice: null
    }
  },
  computed: {
    cars() {
      return CARS.filter((car) => {
        return (this.selectedBrand === null || car.name === this.selectedBrand) &&
            (this.selectedModel === null || car.model === this.selectedModel) &&
            (this.selectedDoors === null || car.doors === this.selectedDoors) &&
            (this.selectedClass === null || car.class === this.selectedClass) &&
            (this.selectedPrice === null || car.price <= this.selectedPrice) &&
            (this.selectedOption === null || car.options.includes(this.selectedOption));
      });
    },
    brands() {
      let result = []

      CARS.forEach(item => {
        if (result.includes(item.name)) {
          return
        }

        result.push(item.name)
      })

      return result
    },
    models() {
      let result = []

      CARS.forEach(item => {
        if (item.name == this.selectedBrand) {
          result.push(item.model)
        }
      })

      return result
    },
    doors() {
      let result = []

      CARS.forEach(item => {
        if (result.includes(item.doors)) {
          return
        }

        result.push(item.doors)
      })

      return result
    },
    carClass() {
      let result = []

      CARS.forEach(item => {
        if (result.includes(item.class)) {
          return
        }

        result.push(item.class)
      })

      return result
    },
    options() {
      let result = []

      CARS.forEach(item => {
        item.options.forEach((option) => {
          if (result.includes(option)) {
            return
          }

          result.push(option)
        })
      })

      return result
    }
  },
  methods: {
    filterClear() {
      this.selectedBrand = null
      this.selectedModel = null
      this.selectedDoors = null
      this.selectedClass = null
      this.selectedOption = null
      this.selectedPrice = null
    },
    setFilterAI(intention) {
      if (!isEmpty(intention.data)) {
        this.selectedBrand = intention.data.carBrand ?? null
        this.selectedModel = intention.data.carModel ?? null
        this.selectedPrice = intention.data.price ?? null
        this.selectedDoors = intention.data.doorsInCar ?? null
        this.selectedClass = intention.data.carClass ?? null
        this.selectedOption = intention.data.carOption ?? null
      }
    }
  },
  watch: {
    selectedBrand() {
      this.selectedModel = null
    }
  },
}
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  background-color: #141414;
  color: #e1e3e6;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.container {
  max-width: 1410px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-top: 40px;
}

h1 {
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  .header-controls {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 30px;

    div {
      background-color: #e1e3e6;
      width: 30px;
      height: 30px;
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: #6f77fe;
      }

      &.active {
        background-color: #6f77fe;
      }
    }
  }
}

.filters {
  padding: 40px 20px;
  border-radius: 20px;
  background-color: #222;

  .search {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;

    .filter-clear {
      outline: none;
      border: none;
      padding: 15px;
      background-color: transparent;
      text-align: start;
      color: #e1e3e6;
      font-size: 16px;
      font-family: "Montserrat", sans-serif;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      gap: 15px;
      align-items: center;
      max-width: max-content;
      grid-column: 3;

      i {
        background-color: #e1e3e6;
        width: 17px;
        height: 17px;
        display: block;
      }
    }
  }

  .assistant {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.content {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);

  .card {
    background-color: #222;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 20px;

    .card-header {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 15px;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;

      span:last-child {
        color: #34b16e;
      }
    }

    .card-body {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 15px;
      justify-content: space-between;

      .info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;

        .info-point {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          gap: 10px;

          span:first-child {
            font-weight: 600;
          }

          span:last-child {
            text-align: end;
          }
        }

        .info-price {
          align-self: end;
          font-size: 18px;
          font-weight: 600;
        }
      }

      img {
        width: 100%;
        max-width: 170px;
        border-radius: 8px;
        display: block;
      }
    }
  }
}

.tooltip {
  display: block !important;
  z-index: 10000;
  max-width: 400px;

  .tooltip-inner {
    background: #414141;
    color: white;
    border-radius: 16px;
    padding: 20px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: #414141;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: #414141;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, .1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}
</style>
