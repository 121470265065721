import axios from "axios";

const URL = 'https://api.proxyapi.ru/openai/v1/chat/completions'
const TOKEN = 'sk-tRHrryQ7byMfAs4cR1zyKoTF3kx7eksY'

const PRE_DATA = {
    "stream": false,
    "model": "gpt-3.5-turbo",
    "temperature": 0.5,
    "presence_penalty": 0,
    "frequency_penalty": 0,
    "top_p": 1,
    "max_tokens": 400
}

export class OpenAIChat {
    /**
     * @param {Context} context
     */
    static async send(context) {
        const request = this.getRequest(context)

        try {
            const {data} = await axios.post(URL, request, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + TOKEN
                }
            })

            return data.choices[0].message.content
        } catch (err) {
            console.error(err)
        }
    }

    /**
     * @param {Context} context
     */
    static getRequest(context) {
        let request = PRE_DATA
        request['messages'] = context.getContext()
        return request
    }
}