import {getRandomNumber} from "@/services/utils";

const ANSWERS = [
    "I'm sorry, I don't have the capability to do that.",
    "I'm unable to perform that action at the moment.",
    "That request is beyond my current capabilities.",
    "I'm not programmed to carry out that task.",
    "I lack the necessary permissions to fulfill that request.",
    "I'm designed for a different set of functions.",
    "That operation is outside the scope of my abilities.",
    "I'm afraid that's not within my operational parameters.",
    "I don't have the resources required to complete that task.",
    "I'm restricted from executing that command."
]

export class UndefinedIntention {
    // eslint-disable-next-line no-unused-vars
    static async getResult(text = null) {
        return {
            message: ANSWERS[getRandomNumber(0, 9)],
            data: {}
        }
    }
}