export class BrowserRecognition {
    #recognition;
    constructor({onStart, onEnd, onResult}) {
        try {
            const Recognition = window.SpeechRecognition || window.webkitSpeechRecognition //Интерфейс Распознавание голоса
            this.#recognition = new Recognition()
        } catch (error) {
            console.error('В этом браузере не поддерживается распознавание речи')
            return
        }

        this.#recognition.continuous = true
        this.#recognition.interimResults = true
        this.#recognition.lang = 'en-US';

        this.#recognition.onresult = (evt) => {
            let isFinal = false

            for (let i=0;i < evt.results.length; i++){
                isFinal = evt.results[i].isFinal //Свойство isFinal интерфейса, доступное только для чтения, SpeechRecognitionResult представляет собой логическое значение, которое указывает, является ли этот результат окончательным ( true) или нет ( false) — если да, то это последний раз, когда этот результат будет возвращен; если нет, то этот результат является промежуточным и может быть обновлен позже.
            }

            const text = Array.from(evt.results)
                .map(result => result[0])
                .map(result => result.transcript)
                .join('')

            onResult({isFinal, text})
        }

        this.#recognition.onstart = (e) => {
            onStart(e)
        }

        this.#recognition.onend = (e) => {
            onEnd(e)
        }
    }

    start() {
        try {
            this.#recognition.start()
        } catch (error) {
            console.error('Ошибка начала записи речи')
        }
    }

    stop() {
        try {
            this.#recognition.stop()
        } catch (error) {
            console.error('Ошибка начала записи речи')
        }
    }
}