<template>
  <div :class="'dropdown-wrapper' + (showOptions ? ' active' : '')">
    <label for="">{{ label }}</label>
    <div class="dropdown" @click="showOptions = !showOptions" v-click-outside="() => {showOptions = false}">
      <div class="placeholder">
        <div class="value">{{ placeholder }}</div>
        <div :class="'icon' + (showOptions ? ' reverse' : '')" style="mask: url(/icons/dropdown.svg) no-repeat center / contain;"></div>
      </div>
      <transition name="slide">
        <div class="list" v-if="showOptions">
          <input type="text" placeholder="Search" v-model="query" @click.stop="() => {}">
          <div class="options">
            <div class="option" v-for="(option, index) in filteredOptions" :key="index" @click.stop="select(option)">{{ option }}</div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  name: "DropdownControl",
  directives: {
    ClickOutside
  },
  props: {
    label: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default() {
        return []
      }
    },
    value: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      query: null,
      showOptions: false,
      selected: null
    }
  },
  computed: {
    placeholder() {
      return this.selected ? this.selected : 'Choose ' + this.label
    },
    filteredOptions() {
      if (!this.query) {
        return this.options
      }

      return this.options.filter((option) => option.toLowerCase().includes(this.query.toLowerCase()))
    }
  },
  methods: {
    select(option) {
      this.selected = option
      this.showOptions = false
      this.$emit('input', option)
    }
  },
  watch: {
    value(val) {
      this.selected = val
    }
  }
}
</script>

<style scoped lang="scss">
.dropdown-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  &.active {
    .dropdown {
      &:hover {
        background-color: #292929;
      }
    }
  }

  label {
    display: block;
    font-size: 14px;
  }

  .dropdown {
    padding: 15px;
    border-radius: 10px;
    background-color: #292929;
    color: #e1e3e6;
    position: relative;
    transition: background-color 0.15s ease;
    cursor: pointer;

    &:hover {
      background-color: #363636;
    }

    .placeholder {
      display: flex;
      flex-direction: row;
      gap: 15px;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;

      .value {
        font-size: 16px;
      }

      .icon {
        background-color: #e1e3e6;
        width: 15px;
        height: 15px;
        transition: transform 0.2s ease;

        &.reverse {
          transform: rotate(180deg);
        }
      }
    }

    .list {
      position: absolute;
      left: 0;
      right: 0;
      max-height: 250px;
      background-color: #292929;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      padding: 15px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      z-index: 1;

      .options {
        display: flex;
        flex-direction: column;
        gap: 15px;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #a9a9a9 #363636;

        .option {
          transition: color 0.1s ease;

          &:hover {
            color: #6f77fe;
          }
        }
      }

      input {
        outline: none;
        border: none;
        background-color: #363636;
        padding: 10px;
        color: #e1e3e6;
        border-radius: 8px;
        font-size: 14px;
      }
    }
  }
}

.slide-enter-active {
  animation: slide-from-top 0.15s;
}
.slide-leave-active {
  animation: slide-from-top 0.15s reverse;
}

@keyframes slide-from-top {
  0% {
    background-color: #363636;
    opacity: 0;
    transform: translateY(-10%);
  }
  100% {
    background-color: #292929;
    opacity: 1;
    transform: translateY(0);
  }
}
</style>