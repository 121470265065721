export class Context {
    #context = []

    addSystem(message) {
        this.#context.push({
            "role": "system",
            "content": message
        })
    }

    addUser(message) {
        this.#context.push({
            "role": "user",
            "content": message
        })
    }

    getContext() {
        return this.#context
    }
}