export const CARS = [{
    "name": "Volkswagen",
    "model": "Touareg",
    "price": 12874,
    "class": "E",
    "doors": 5,
    "image": "https:////avatars.mds.yandex.net/get-verba/216201/2a0000016099a5db45701c73672b78fd4fcb/wizardv3mr",
    "options": []
}, {
    "name": "Mercedes-Benz",
    "model": "GLA",
    "price": 23739,
    "class": "C",
    "doors": 5,
    "image": "https:////avatars.mds.yandex.net/get-verba/787013/2a000001609d0ff1dd70d9582f2d26f7e606/wizardv3mr",
    "options": ["Cruise control", "Multi wheel", "Xenon", "Auto park", "Heated wash system", "Airbag passenger", "Roof rails", "Lock", "Electro mirrors", "Mirrors heat", "Start stop function", "Drowsy driver alert system", "Automatic lighting control", "Collision prevention assist", "Computer", "Seat transformation", "Light cleaner", "Airbag side", "Abs", "Wheel leather", "Esp", "Audiopreparation", "Ashtray and cigarette lighter", "Front centre armrest", "Servo", "Steering wheel gear shift paddles", "Electro window back", "17 inch wheels", "Park assist f", "Combo interior", "Condition", "Park assist r", "Knee airbag", "Airbag driver", "Isofix", "Electro window front", "Light sensor", "Hcc", "Airbag curtain", "Alarm", "Paint metallic", "Traffic sign recognition", "Tyre pressure", "Migration flag", "Front seats heat", "Wheel configuration2", "Wheel configuration1", "Immo", "12v socket", "Third rear headrest"]
}, {
    "name": "Volkswagen",
    "model": "Polo",
    "price": 10213,
    "class": "B",
    "doors": 4,
    "image": "https:////avatars.mds.yandex.net/get-verba/787013/2a000001609ce48e5c49ce63b9c74eec5ea0/wizardv3mr",
    "options": ["Heated wash system", "Airbag passenger", "Lock", "Electro mirrors", "Mirrors heat", "Computer", "Seat transformation", "Fabric seats", "Steel wheels", "Abs", "Usb", "Ashtray and cigarette lighter", "Servo", "Electro window back", "Condition", "Airbag driver", "Isofix", "Aux", "Electro window front", "Paint metallic", "Audiosystem cd", "Migration flag", "Front seats heat", "Wheel configuration2", "Wheel configuration1", "Immo", "Third rear headrest"]
}, {
    "name": "Hyundai",
    "model": "Palisade",
    "price": 64101,
    "class": "E",
    "doors": 5,
    "image": "https:////avatars.mds.yandex.net/get-verba/3587101/2a00000182279061e5a31f4828e1bba676b7/wizardv3mr",
    "options": ["Multi wheel", "Auto park", "Heated wash system", "Airbag passenger", "Roof rails", "Bas", "Lock", "Electro mirrors", "Mirrors heat", "Leather", "Massage seats", "Driver seat memory", "Glonass", "Front seats heat vent", "Automatic lighting control", "Collision prevention assist", "Vsm", "Computer", "Seat transformation", "Wheel power", "Dha", "Airbag side", "Electronic gage panel", "Electro trunk", "Auto cruise", "Abs", "Wheel leather", "Auto mirrors", "Asr", "Tinted glass", "Esp", "Usb", "Spare wheel", "Audiosystem tv", "Lane keeping assist", "Front centre armrest", "Front seat support", "20 inch wheels", "Drl", "Steering wheel gear shift paddles", "Electro window back", "Multizone climate control", "Hatch", "Body mouldings", "Park assist f", "Rear seats heat", "Driver seat updown", "Wheel heat", "Led lights", "Music super", "Park assist r", "Wireless charger", "Airbag driver", "Isofix", "Roller blinds for rear side windows", "Projection display", "Electro window front", "Light sensor", "Hcc", "Airbag curtain", "Keyless entry", "Passenger seat electric", "Blind spot", "Traffic sign recognition", "Start button", "Rain sensor", "Tyre pressure", "Migration flag", "Rear seat heat vent", "Front seats heat", "Power child locks rear doors", "Bluetooth", "Wheel configuration2", "Wheel configuration1", "Immo", "12v socket", "Third rear headrest", "360 camera"]
}, {
    "name": "Volvo",
    "model": "XC90",
    "price": 24445,
    "class": "E",
    "doors": 5,
    "image": "https:////avatars.mds.yandex.net/get-verba/787013/2a000001609ae874f14b7837a86cac97a93c/wizardv3mr",
    "options": []
}, {
    "name": "Changan",
    "model": "UNI-K",
    "price": 36939,
    "class": "E",
    "doors": 5,
    "image": "https:////avatars.mds.yandex.net/get-verba/3587101/2a00000178c6752fe0348d6a24aa4bdbe62f/wizardv3mr",
    "options": ["Multi wheel", "Airbag passenger", "Decorative interior lighting", "Bas", "Lock", "Electro mirrors", "Easy trunk opening", "Mirrors heat", "Driver seat memory", "Glonass", "Front seats heat vent", "Collision prevention assist", "Computer", "High beam assist", "Seat transformation", "Android auto", "Wheel power", "Dha", "Airbag side", "Electronic gage panel", "Electro trunk", "Auto cruise", "Abs", "Wheel leather", "Climate control 2", "Auto mirrors", "Esp", "Usb", "Audiosystem tv", "Lane keeping assist", "Front centre armrest", "Apple carplay", "21 inch wheels", "Drl", "Electro window back", "Reduce spare wheel", "Hatch", "Park assist f", "Combo interior", "Rear seats heat", "Led lights", "Music super", "Park assist r", "Wireless charger", "Airbag driver", "Isofix", "Electro window front", "Light sensor", "Hcc", "Airbag curtain", "Keyless entry", "Passenger seat electric", "Leather gear stick", "Blind spot", "Start button", "Ptf", "Rain sensor", "Tyre pressure", "Voice recognition", "Remote engine start", "Panorama roof", "Front seats heat", "Power child locks rear doors", "Bluetooth", "Wheel configuration1", "Immo", "Windscreen heat", "12v socket", "Third rear headrest", "360 camera"]
}, {
    "name": "Zeekr",
    "model": "001",
    "price": 73879,
    "class": "E",
    "doors": 5,
    "image": "https:////avatars.mds.yandex.net/get-verba/1540742/2a00000180f196721ea727ab4e0fa013ae1b/wizardv3mr",
    "options": ["Sport seats", "E adjustment wheel", "Multi wheel", "Airbag passenger", "Navigation", "Bas", "Lock", "Electro mirrors", "Mirrors heat", "Massage seats", "Driver seat memory", "Front seats heat vent", "Computer", "High beam assist", "Android auto", "Wheel power", "Airbag side", "Electronic gage panel", "Electro trunk", "Abs", "Auto mirrors", "Asr", "Esp", "Adaptive light", "Usb", "Audiosystem tv", "Front centre armrest", "Apple carplay", "Drl", "Electro window back", "Multizone climate control", "Park assist f", "Rear seats heat", "22 inch wheels", "Wheel heat", "Led lights", "Park assist r", "Knee airbag", "Wireless charger", "Airbag driver", "Isofix", "Projection display", "Drive mode sys", "Electro window front", "Light sensor", "Airbag curtain", "Keyless entry", "Passenger seat electric", "Start button", "Rain sensor", "Tyre pressure", "Voice recognition", "Audiosystem cd", "Migration flag", "Panorama roof", "Front seats heat", "Power child locks rear doors", "Bluetooth", "Immo", "12v socket", "360 camera"]
}, {
    "name": "Volkswagen",
    "model": "ID.4",
    "price": 46174,
    "class": "C",
    "doors": 5,
    "image": "https:////avatars.mds.yandex.net/get-verba/997355/2a00000182b5a1b67fca51fdd399a9d88fc0/wizardv3mr",
    "options": ["Multi wheel", "Auto park", "Airbag passenger", "Navigation", "Roof rails", "Decorative interior lighting", "Bas", "Lock", "Electro mirrors", "Driver seat support", "Easy trunk opening", "Mirrors heat", "Leather", "Seat memory", "Massage seats", "Glonass", "Drowsy driver alert system", "Automatic lighting control", "Collision prevention assist", "Computer", "High beam assist", "Seat transformation", "Android auto", "Wheel power", "Airbag side", "Electro trunk", "Auto cruise", "Abs", "Auto mirrors", "Asr", "Tinted glass", "Esp", "Adaptive light", "Usb", "Audiosystem tv", "Lane keeping assist", "Front centre armrest", "Apple carplay", "Drl", "Electro window back", "Multizone climate control", "Park assist f", "19 inch wheels", "Wheel heat", "Led lights", "Park assist r", "Wireless charger", "Airbag driver", "Isofix", "Drive mode sys", "Electro window front", "Light sensor", "Hcc", "Airbag curtain", "Keyless entry", "Passenger seat electric", "Blind spot", "Traffic sign recognition", "Start button", "Ptf", "Rain sensor", "Tyre pressure", "Remote engine start", "Audiosystem cd", "Migration flag", "Panorama roof", "Front seats heat", "Power child locks rear doors", "Bluetooth", "Wheel configuration2", "Wheel configuration1", "Immo", "Windscreen heat", "12v socket", "360 camera"]
}, {
    "name": "Genesis",
    "model": "GV80",
    "price": 68447,
    "class": "E",
    "doors": 5,
    "image": "https:////avatars.mds.yandex.net/get-verba/216201/2a0000017fb83a8a2952fe3113f509c19eb7/wizardv3mr",
    "options": ["E adjustment wheel", "Multi wheel", "Auto park", "Airbag passenger", "Navigation", "Roof rails", "Bas", "Lock", "Electro mirrors", "Activ suspension", "Mirrors heat", "Leather", "Driver seat memory", "Electro rear seat", "Glonass", "Front seats heat vent", "Drowsy driver alert system", "Passenger seat updown", "Collision prevention assist", "Computer", "High beam assist", "Seat transformation", "Android auto", "Wheel power", "Airbag rear side", "Airbag side", "Electronic gage panel", "Electro trunk", "Auto cruise", "Abs", "Wheel leather", "Auto mirrors", "Asr", "Tinted glass", "Esp", "Usb", "Spare wheel", "Audiosystem tv", "Lane keeping assist", "Front centre armrest", "Apple carplay", "Front seat support", "20 inch wheels", "Drl", "Steering wheel gear shift paddles", "Electro window back", "Multizone climate control", "Park assist f", "Rear seats heat", "Wheel heat", "Led lights", "Music super", "Park assist r", "Knee airbag", "Wireless charger", "Airbag driver", "Isofix", "Roller blinds for rear side windows", "Projection display", "Drive mode sys", "Electro window front", "Light sensor", "Hcc", "Airbag curtain", "Keyless entry", "Passenger seat electric", "Paint metallic", "Blind spot", "Start button", "Rain sensor", "Tyre pressure", "Remote engine start", "Panorama roof", "Rear seat heat vent", "Front seats heat", "Bluetooth", "Wheel configuration2", "Wheel configuration1", "Immo", "Third rear headrest", "360 camera"]
}, {
    "name": "Land Rover",
    "model": "Range Rover Sport",
    "price": 42046,
    "class": "J",
    "doors": 5,
    "image": "https:////avatars.mds.yandex.net/get-verba/787013/2a000001609a348f33b59361d6e91e138a7b/wizardv3mr",
    "options": ["Cruise control", "Multi wheel", "Airbag passenger", "Navigation", "Lock", "Electro mirrors", "Mirrors heat", "Volume sensor", "Leather", "Seat memory", "Start stop function", "Computer", "Seat transformation", "Light cleaner", "Airbag side", "Abs", "Wheel leather", "Climate control 2", "Esp", "Usb", "Front centre armrest", "Servo", "Electro window back", "Body mouldings", "Rear seats heat", "19 inch wheels", "Laminated safety glass", "Park assist r", "Airbag driver", "Isofix", "Aux", "Electro window front", "Light sensor", "Hcc", "Airbag curtain", "Passenger seat electric", "Alarm", "Start button", "Ptf", "Rain sensor", "Audiosystem cd", "Migration flag", "Front seats heat", "Bluetooth", "Wheel configuration2", "Wheel configuration1", "Immo", "Windscreen heat", "Third rear headrest"]
}, {
    "name": "Ford",
    "model": "S-MAX",
    "price": 23739,
    "class": "M",
    "doors": 5,
    "image": "https:////avatars.mds.yandex.net/get-verba/1030388/2a000001609cfc9d3a6569cd5ff21eca2533/wizardv3mr",
    "options": []
}, {
    "name": "Mercedes-Benz",
    "model": "E-Класс",
    "price": 58451,
    "class": "E",
    "doors": 4,
    "image": "https:////avatars.mds.yandex.net/get-verba/997355/2a00000176b7d3f6a4f92df665019bd8cd6f/wizardv3mr",
    "options": ["Cruise control", "Eco leather", "E adjustment wheel", "Multi wheel", "Auto park", "Heated wash system", "Airbag passenger", "Navigation", "Decorative interior lighting", "Bas", "Lock", "Wheel memory", "Electro mirrors", "Activ suspension", "Mirrors heat", "Volume sensor", "Cooling box", "Seat memory", "Sport pedals", "Body kit", "Glonass", "Passenger seat updown", "Collision prevention assist", "Computer", "Seat transformation", "Android auto", "Airbag side", "Electronic gage panel", "Electro trunk", "Abs", "Wheel leather", "Climate control 2", "Auto mirrors", "Black roof", "Asr", "Esp", "Usb", "Audiosystem tv", "Front centre armrest", "Apple carplay", "Front seat support", "Rear camera", "Servo", "Drl", "Steering wheel gear shift paddles", "Electro window back", "Park assist f", "19 inch wheels", "Led lights", "Music super", "Park assist r", "Knee airbag", "Airbag driver", "Isofix", "Aux", "Drive mode sys", "Electro window front", "Light sensor", "Hcc", "Airbag curtain", "Passenger seat electric", "Alarm", "Paint metallic", "Rain sensor", "Tyre pressure", "Voice recognition", "Front seats heat", "Power child locks rear doors", "Bluetooth", "Wheel configuration2", "Wheel configuration1", "Immo", "12v socket", "Third rear headrest"]
}, {
    "name": "Hyundai",
    "model": "Tucson",
    "price": 27792,
    "class": "C",
    "doors": 5,
    "image": "https:////avatars.mds.yandex.net/get-verba/937147/2a00000164daf81735f5ead39fe61e3e205e/wizardv3mr",
    "options": ["Cruise control", "Multi wheel", "Airbag passenger", "Roof rails", "Bas", "Lock", "Electro mirrors", "Mirrors heat", "Cooling box", "Glonass", "Computer", "Seat transformation", "Android auto", "Wheel power", "Fabric seats", "Airbag side", "Abs", "Wheel leather", "Climate control 2", "Asr", "Tinted glass", "Esp", "Usb", "Spare wheel", "Ashtray and cigarette lighter", "Front centre armrest", "Apple carplay", "Rear camera", "Electro window back", "17 inch wheels", "Wheel heat", "Park assist r", "Airbag driver", "Isofix", "Aux", "Electro window front", "Light sensor", "Hcc", "Airbag curtain", "Alarm", "Leather gear stick", "Ptf", "Tyre pressure", "Audiosystem cd", "Front seats heat", "Power child locks rear doors", "Bluetooth", "Wheel configuration2", "Wheel configuration1", "Immo", "12v socket"]
}, {
    "name": "Changan",
    "model": "UNI-V",
    "price": 35853,
    "class": "C",
    "doors": 5,
    "image": "https:////avatars.mds.yandex.net/get-verba/787013/2a00000183a202727c36f855d632ab371dd8/wizardv3mr",
    "options": []
}, {
    "name": "BMW",
    "model": "7 серии",
    "price": 71706,
    "class": "F",
    "doors": 4,
    "image": "https:////avatars.mds.yandex.net/get-verba/216201/2a0000016dfd97a2fadd33031bec5f1eb2af/wizardv3mr",
    "options": []
}, {
    "name": "Chevrolet",
    "model": "Cruze",
    "price": 7062,
    "class": "C",
    "doors": 4,
    "image": "https:////avatars.mds.yandex.net/get-verba/216201/2a000001609d0456b8918f89d1abdfd16c7d/wizardv3mr",
    "options": ["Airbag passenger", "Lock", "Electro mirrors", "Mirrors heat", "Computer", "Seat transformation", "Wheel power", "Fabric seats", "Airbag side", "Abs", "Electro window back", "Condition", "Airbag driver", "Electro window front", "Alarm", "Ptf", "Audiosystem cd", "Migration flag", "Front seats heat", "Wheel configuration2", "Wheel configuration1", "Immo"]
}, {
    "name": "Changan",
    "model": "CS35PLUS",
    "price": 15971,
    "class": "B",
    "doors": 5,
    "image": "https:////avatars.mds.yandex.net/get-verba/787013/2a00000180472af706e555991c27ce1f43cc/wizardv3mr",
    "options": ["Cruise control", "Multi wheel", "Airbag passenger", "Roof rails", "Bas", "Lock", "Electro mirrors", "Mirrors heat", "Glonass", "Computer", "Seat transformation", "Wheel power", "Abs", "Wheel leather", "Climate control 1", "Auto mirrors", "Asr", "Esp", "Usb", "Audiopreparation", "Front centre armrest", "Rear camera", "Electro window back", "Reduce spare wheel", "17 inch wheels", "Body mouldings", "Combo interior", "Driver seat updown", "Park assist r", "Airbag driver", "Isofix", "Aux", "Electro window front", "Light sensor", "Hcc", "Keyless entry", "Paint metallic", "Start button", "Ptf", "Tyre pressure", "Front seats heat", "Bluetooth", "Wheel configuration2", "Wheel configuration1", "Immo", "12v socket"]
}, {
    "name": "Changan",
    "model": "CS55",
    "price": 19991,
    "class": "C",
    "doors": 5,
    "image": "https:////avatars.mds.yandex.net/get-verba/1540742/2a0000016f3244d987d3acf8c4de4a4fc801/wizardv3mr",
    "options": ["Cruise control", "Eco leather", "Multi wheel", "Airbag passenger", "Roof rails", "Bas", "Lock", "Electro mirrors", "Mirrors heat", "Glonass", "Collision prevention assist", "Computer", "Seat transformation", "Wheel power", "Dha", "Abs", "Wheel leather", "Climate control 2", "Auto mirrors", "Asr", "Tinted glass", "Esp", "Usb", "Front centre armrest", "Rear camera", "Electro window back", "18 inch wheels", "Reduce spare wheel", "Hatch", "Driver seat updown", "Music super", "Park assist r", "Airbag driver", "Isofix", "Electro window front", "Hcc", "Keyless entry", "Paint metallic", "Start button", "Rain sensor", "Tyre pressure", "Remote engine start", "Folding tables rear", "Front seats heat", "Power child locks rear doors", "Bluetooth", "Wheel configuration2", "Wheel configuration1", "Immo", "12v socket"]
}, {
    "name": "Toyota",
    "model": "Alphard",
    "price": 64101,
    "class": "M",
    "doors": 5,
    "image": "https:////avatars.mds.yandex.net/get-verba/787013/2a00000162249d4c9896c8f7ce0dc34c3e38/wizardv3mr",
    "options": []
}, {
    "name": "Volkswagen",
    "model": "Touareg",
    "price": 27161,
    "class": "E",
    "doors": 5,
    "image": "https:////avatars.mds.yandex.net/get-verba/1030388/2a000001609d650f0d92d74228ffad879d23/wizardv3mr",
    "options": ["Cruise control", "Multi wheel", "Xenon", "Heated wash system", "Airbag passenger", "Navigation", "Roof rails", "Lock", "Door sill panel", "Electro mirrors", "Mirrors heat", "Cooling box", "Seat memory", "Sport pedals", "Drowsy driver alert system", "Automatic lighting control", "Computer", "High beam assist", "Seat transformation", "Light cleaner", "Fabric seats", "Airbag side", "Electro trunk", "Abs", "Wheel leather", "Auto mirrors", "Esp", "Ashtray and cigarette lighter", "Front centre armrest", "Servo", "Electro window back", "18 inch wheels", "Multizone climate control", "Hatch", "Body mouldings", "Park assist f", "Wheel heat", "Park assist r", "Knee airbag", "Airbag driver", "Isofix", "Aux", "Electro window front", "Light sensor", "Hcc", "Airbag curtain", "Keyless entry", "Programmed block heater", "Passenger seat electric", "Alarm", "Leather gear stick", "Start button", "Ptf", "Rain sensor", "Audiosystem cd", "Migration flag", "Front seats heat", "Power child locks rear doors", "Wheel configuration2", "Wheel configuration1", "Immo", "12v socket"]
}, {
    "name": "Hyundai",
    "model": "Solaris",
    "price": 20860,
    "class": "B",
    "doors": 4,
    "image": "https:////avatars.mds.yandex.net/get-verba/1030388/2a0000017080b874c59ece700722941db579/wizardv3mr",
    "options": ["Cruise control", "Multi wheel", "Airbag passenger", "Bas", "Lock", "Electro mirrors", "Mirrors heat", "Glonass", "Vsm", "Computer", "Seat transformation", "Android auto", "Wheel power", "Fabric seats", "Steel wheels", "Abs", "Wheel leather", "Esp", "Usb", "Apple carplay", "Electro window back", "Reduce spare wheel", "Driver seat updown", "Wheel heat", "Condition", "15 inch wheels", "Airbag driver", "Isofix", "Electro window front", "Hcc", "Alarm", "Tyre pressure", "Audiosystem cd", "Front seats heat", "Bluetooth", "Wheel configuration2", "Wheel configuration1", "Immo", "12v socket"]
}, {
    "name": "Hyundai",
    "model": "Elantra",
    "price": 30964,
    "class": "C",
    "doors": 4,
    "image": "https:////avatars.mds.yandex.net/get-verba/937147/2a00000175fead183f8c2b9a9f66141d34cf/wizardv3mr",
    "options": ["Cruise control", "Eco leather", "Multi wheel", "Airbag passenger", "Navigation", "Bas", "Lock", "Electro mirrors", "Passenger seat updown", "Computer", "High beam assist", "Seat transformation", "Android auto", "Wheel power", "Driver seat electric", "Airbag side", "Electronic gage panel", "Abs", "Wheel leather", "Auto mirrors", "Asr", "Esp", "Audiosystem tv", "Lane keeping assist", "Front centre armrest", "Apple carplay", "Rear camera", "Drl", "Electro window back", "Hatch", "17 inch wheels", "Park assist f", "Driver seat updown", "Wheel heat", "Condition", "Led lights", "Park assist r", "Wireless charger", "Airbag driver", "Isofix", "Aux", "Drive mode sys", "Electro window front", "Light sensor", "Hcc", "Airbag curtain", "Keyless entry", "Alarm", "Start button", "Tyre pressure", "Voice recognition", "Audiosystem cd", "Migration flag", "Front seats heat", "Power child locks rear doors", "Bluetooth", "Immo", "12v socket"]
}, {
    "name": "Hyundai",
    "model": "Elantra",
    "price": 29117,
    "class": "C",
    "doors": 4,
    "image": "https:////avatars.mds.yandex.net/get-verba/937147/2a00000175fead183f8c2b9a9f66141d34cf/wizardv3mr",
    "options": ["Cruise control", "Eco leather", "Multi wheel", "Airbag passenger", "Navigation", "Bas", "Lock", "Electro mirrors", "Passenger seat updown", "Computer", "High beam assist", "Seat transformation", "Android auto", "Wheel power", "Driver seat electric", "Airbag side", "Electronic gage panel", "Abs", "Wheel leather", "Auto mirrors", "Asr", "Esp", "Audiosystem tv", "Lane keeping assist", "Front centre armrest", "Apple carplay", "Rear camera", "Drl", "Electro window back", "Hatch", "17 inch wheels", "Park assist f", "Driver seat updown", "Wheel heat", "Condition", "Led lights", "Park assist r", "Wireless charger", "Airbag driver", "Isofix", "Aux", "Drive mode sys", "Electro window front", "Light sensor", "Hcc", "Airbag curtain", "Keyless entry", "Alarm", "Start button", "Tyre pressure", "Voice recognition", "Audiosystem cd", "Migration flag", "Front seats heat", "Power child locks rear doors", "Bluetooth", "Immo", "12v socket"]
}, {
    "name": "Mercedes-Benz",
    "model": "GLC Coupe",
    "price": 64101,
    "class": "D",
    "doors": 5,
    "image": "https:////avatars.mds.yandex.net/get-verba/787013/2a0000016ac5eb7dcb185e369fb3bb468adf/wizardv3mr",
    "options": ["Cruise control", "Eco leather", "Multi wheel", "Heated wash system", "Airbag passenger", "Decorative interior lighting", "Bas", "Lock", "Door sill panel", "Electro mirrors", "Volume sensor", "Seat memory", "Glonass", "Passenger seat updown", "Collision prevention assist", "Vsm", "Computer", "Android auto", "Wheel power", "Dha", "Airbag side", "Electro trunk", "Abs", "Wheel leather", "Climate control 2", "Auto mirrors", "Black roof", "Asr", "Esp", "Usb", "Windcleaner heat", "Audiosystem tv", "Front centre armrest", "Apple carplay", "Front seat support", "Rear camera", "Drl", "Steering wheel gear shift paddles", "Electro window back", "Reduce spare wheel", "Body mouldings", "Park assist f", "Sport suspension", "19 inch wheels", "Wheel heat", "Led lights", "Park assist r", "Knee airbag", "Airbag driver", "Isofix", "Drive mode sys", "Electro window front", "Light sensor", "Hcc", "Keyless entry", "Passenger seat electric", "Alarm", "Start button", "Rain sensor", "Tyre pressure", "Audiosystem cd", "Front seats heat", "Power child locks rear doors", "Wheel configuration2", "Wheel configuration1", "Immo", "12v socket"]
}, {
    "name": "Chery",
    "model": "Tiggo 8 Pro Max",
    "price": 31996,
    "class": "D",
    "doors": 5,
    "image": "https:////avatars.mds.yandex.net/get-verba/787013/2a0000018042a76060fc6dfe6d26e5b38ea6/wizardv3mr",
    "options": []
}, {
    "name": "Skoda",
    "model": "Octavia",
    "price": 21729,
    "class": "C",
    "doors": 5,
    "image": "https:////avatars.mds.yandex.net/get-verba/937147/2a0000016092503fe920ba3753af5fec943c/wizardv3mr",
    "options": ["Cruise control", "Multi wheel", "Heated wash system", "Airbag passenger", "Lock", "Door sill panel", "Electro mirrors", "Mirrors heat", "Volume sensor", "Cooling box", "Seat memory", "Start stop function", "Automatic lighting control", "Computer", "Seat transformation", "Light cleaner", "Airbag side", "Abs", "Wheel leather", "Climate control 1", "Auto mirrors", "Esp", "Usb", "Ashtray and cigarette lighter", "Front centre armrest", "Servo", "Electro window back", "17 inch wheels", "Park assist f", "Combo interior", "Rear seats heat", "Wheel heat", "Led lights", "Music super", "Park assist r", "Airbag driver", "Isofix", "Aux", "Electro window front", "Light sensor", "Hcc", "Airbag curtain", "Passenger seat electric", "Alarm", "Start button", "Ptf", "Rain sensor", "Voice recognition", "Audiosystem cd", "Migration flag", "Front seats heat", "Bluetooth", "Wheel configuration2", "Wheel configuration1", "Immo", "12v socket"]
}]