import {UndefinedIntention} from "@/intentions/UndefinedIntention";
import {Context} from "@/models/Context";
import {OpenAIChat} from "@/services/openAIChat";
import {removePunctuationAndLowercase} from "@/services/utils";
import {INTENTION_BOT} from "@/personality/intentionBot";
import {DialogIntention} from "@/intentions/DialogIntention";
import {FliterCarsIntention} from "@/intentions/FliterCarsIntention";

const INTENTIONS = {
    "отфильтровать автомобили": FliterCarsIntention,
    "просто поболтать": DialogIntention,
    "неизвестно": UndefinedIntention
}

export class IntentionDetector {
    static async detect(text) {
        const context = new Context()
        context.addSystem(INTENTION_BOT)
        context.addUser('К какому из предложенных намерений относится это намерение: ' + text + '.\n Намерения на выбор: отфильтровать автомобили, просто поболтать, неизвестно')
        let preIntention = await OpenAIChat.send(context)
        const intentionString = removePunctuationAndLowercase(preIntention)
        let intention = null

        intention = INTENTIONS[intentionString] ?? UndefinedIntention

        return intention.getResult(text)
    }
}