import {Context} from "@/models/Context";
import {DIALOG_BOT} from "@/personality/dialogBot";
import {OpenAIChat} from "@/services/openAIChat";

export class DialogIntention {
    static async getResult(text = null) {
        const context = new Context()
        context.addSystem(DIALOG_BOT)
        context.addUser(text)

        const data = await OpenAIChat.send(context)

        return {
            message: data,
            data: {}
        }
    }
}