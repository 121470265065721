import {Context} from "@/models/Context";
import {OpenAIChat} from "@/services/openAIChat";
import {JSON_BOT} from "@/personality/jsonBot";
import {DIALOG_BOT} from "@/personality/dialogBot";

export class FliterCarsIntention {
    static async getResult(text = null) {
        const contextJson = new Context()
        contextJson.addSystem(JSON_BOT)
        contextJson.addUser('разбей на полезные данные этот запрос: ' + text + '. Ответ должен соответствовать формату: {"carBrand": string||null, "carModel": ?string, "price": ?float, "doorsInCar": ?number, "carClass": ?string, "carOption": ?string}')

        const json = await OpenAIChat.send(contextJson)
        const filters = JSON.parse(json)

        const contextAnswer = new Context()
        contextAnswer.addSystem(DIALOG_BOT)
        contextAnswer.addUser(text)

        const answer = await OpenAIChat.send(contextAnswer)

        return {
            message: answer,
            data: filters
        }
    }
}