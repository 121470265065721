<template>
  <div class="assistant">
    <small>{{ lastQuestion }}</small>
    <h3 style="text-align: center">{{ answer }}</h3>
    <div id=”assistant” style="cursor: pointer;" ref="assistant" @click="start"></div>
  </div>
</template>
<script>
import SiriWave from "siriwave";
import {BrowserRecognition} from "@/recognizers/BrowserRecognition";
import {IntentionDetector} from "@/services/intentionDetector";

export default {
  name: "AssistantControl",
  data() {
    return {
      instance: null,
      recognizer: null,
      lastQuestion: null,
      currentQuery: null
    }
  },
  computed: {
    answer() {
      return this.currentQuery ? this.currentQuery : 'How can I help you?'
    }
  },
  methods: {
    idle() {
      this.instance.setSpeed(0.05)
      this.instance.setAmplitude(0.5)
    },
    listen() {
      this.instance.setSpeed(0.07)
      this.instance.setAmplitude(5)
    },
    async handle(text) {
      this.instance.setSpeed(0.06)
      this.instance.setAmplitude(2)
      this.recognizer.stop()

      const intention = await IntentionDetector.detect(text)
      this.currentQuery = intention.message
      this.lastQuestion = text

      this.idle()
      this.$emit('result', intention)
    },
    async onResult({isFinal, text}) {
      this.currentQuery = text

      if (isFinal) {
        await this.handle(text)
      }
    },
    start() {
      this.lastQuestion = null
      this.currentQuery = null
      this.listen()
      this.recognizer.start()
    }
  },
  mounted() {
    this.instance = new SiriWave({
      container: this.$refs.assistant,
      width: 500,
      speed: 0.05,
      height: 120,
      style: 'ios9',
      amplitude: 1,
      curveDefinition: [
        {
          color: "255,255,255",
          supportLine: true,
        },
        {
          color: "15, 82, 169",
        },
        {
          color: "173, 57, 76",
        },
        {
          color: "48, 220, 155",
        },]
    })
    this.recognizer = new BrowserRecognition({
      onResult: this.onResult,
      onStart: () => {
        this.listen()
      },
      onEnd: () => {
        //this.handle()
      }
    })

    this.recognizer.start()
  }
}
</script>

<style scoped lang="scss">

</style>