export function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function removePunctuationAndLowercase(str) {
    const punctuationRegex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
    const stringWithoutPunctuation = str.replace(punctuationRegex, '');
    return stringWithoutPunctuation.toLowerCase();
}

export function isEmpty(variable) {
    // Проверка на null или undefined
    if (variable === null || variable === undefined) {
        return true;
    }

    // Проверка на строку
    if (typeof variable === 'string' && variable.trim() === '') {
        return true;
    }

    // Проверка на массив
    if (Array.isArray(variable) && variable.length === 0) {
        return true;
    }

    // Проверка на объект
    if (typeof variable === 'object' && Object.keys(variable).length === 0) {
        return true;
    }

    return false;
}